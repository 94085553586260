import React from "react";
import lottie from "lottie-web";
import reactLogo from "./static/qc.json";
import logo from "./static/logo.png";

export default function App() {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: reactLogo
    });
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
     <img src={logo} style={{ width: 400, height: 400 }} alt="Quantek" />
     {/* <div id="react-logo" style={{ width: 800, height: 800 }} /> */}
    </div>
  );
}
